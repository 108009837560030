import React, { useState } from 'react'
import { motion } from 'framer-motion'
import ProjectCard from './ProjectCard'

const projects = [
  {
    name: "Smart Touristic Tax",
    description: "Facilitates the management of tourist tax and secure storage of guest data in Andorra.",
    tech: "React, NodeJS, MongoDB, Agile Methodology",
    github: "https://github.com/yourusername/smart-touristic-tax",
    website: "https://smart-touristic-tax.com",
    image: "/placeholder.svg?height=200&width=300"
  },
  {
    name: "BeeMovr",
    description: "3rd Big Prize Winner at HackMIT 2023. Maps for beekeepers using real-time weather data to predict honey yield.",
    tech: "TypeScript, JavaScript, CSS",
    github: "https://github.com/soobinrho/BeeMovr",
    website: "https://www.beemovr.com",
    image: "/placeholder.svg?height=200&width=300"
  },
  {
    name: "CredaCapital",
    description: "Co-Founder. Developed algorithmic trading bots for index funds and cryptocurrency assets.",
    tech: "Python, CCXT, Kraken API, Binance API, C#, NinjaTrader, Metatrader, MQL5",
    github: "https://github.com/yourusername/credacapital",
    website: "https://credacapital.com",
    image: "/placeholder.svg?height=200&width=300"
  },
  {
    name: "Civita Technologies",
    description: "Co-Founder. Software company creating AI-powered entertaining apps, NFT projects, and DAOs.",
    tech: "Various cutting-edge technologies",
    github: "https://github.com/civita-technologies",
    website: "https://civita-technologies.com",
    image: "/placeholder.svg?height=200&width=300"
  }
]

export default function ProjectsSection() {
  const [expandedProject, setExpandedProject] = useState<string | null>(null)

  const toggleExpansion = (projectName: string) => {
    setExpandedProject(prevExpanded => prevExpanded === projectName ? null : projectName)
  }

  return (
    <motion.section
      key="projects"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
      className="mb-20"
    >
      <h2 className="text-4xl font-bold mb-8 text-center">Projects</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {projects.map((project, index) => (
          <ProjectCard
            key={project.name}
            project={project}
            index={index}
            isExpanded={expandedProject === project.name}
            toggleExpansion={() => toggleExpansion(project.name)}
          />
        ))}
      </div>
    </motion.section>
  )
}