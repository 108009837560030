import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import Header from './Header'
import AboutSection from './AboutSection'
import SkillsSection from './SkillsSection'
import ProjectsSection from './ProjectsSection'
import ContactSection from './ContactSection'
import Footer from './Footer'

export default function EnhancedInteractivePersonalWebsite() {
  const [activeSection, setActiveSection] = useState('about')
  const [theme, setTheme] = useState('dark')
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      setMousePosition({ x: e.clientX, y: e.clientY })
    }

    window.addEventListener('mousemove', handleMouseMove)

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
    }
  }, [])

  const toggleTheme = () => {
    setTheme(theme === 'dark' ? 'light' : 'dark')
  }

  return (
    <div className={`min-h-screen ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'} font-sans transition-colors duration-300 overflow-x-hidden`}>
      <motion.div
        className="fixed inset-0 pointer-events-none z-0"
        animate={{
          background: `radial-gradient(800px at ${mousePosition.x}px ${mousePosition.y}px, ${theme === 'dark' ? 'rgba(29, 78, 216, 0.25)' : 'rgba(59, 130, 246, 0.25)'}, transparent 80%)`
        }}
      />
      <Header activeSection={activeSection} setActiveSection={setActiveSection} theme={theme} toggleTheme={toggleTheme} />
      <main className="container mx-auto px-4 py-8 relative z-10">
        {activeSection === 'about' && <AboutSection />}
        {activeSection === 'skills' && <SkillsSection />}
        {activeSection === 'projects' && <ProjectsSection />}
        <ContactSection />
      </main>
      <Footer />
    </div>
  )
}