import React from 'react'
import { motion } from 'framer-motion'

const skills = [
  { name: "Blockchain", level: 90 },
  { name: "Smart Contracts", level: 85 },
  { name: "Python", level: 95 },
  { name: "Java", level: 80 },
  { name: "Ruby", level: 75 },
  { name: "C/C++", level: 85 },
  { name: "C#", level: 80 },
  { name: "React", level: 90 },
  { name: "Node.js", level: 85 },
  { name: "MongoDB", level: 80 },
  { name: "PostgreSQL", level: 75 },
  { name: "Agile Methodology", level: 90 },
  { name: "Machine Learning", level: 80 },
  { name: "AI", level: 85 },
  { name: "Algorithmic Trading", level: 90 },
  { name: "API Development", level: 85 }
]

export default function SkillsSection() {
  return (
    <motion.section
      key="skills"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
      className="mb-20"
    >
      <h2 className="text-4xl font-bold mb-8 text-center">Skills</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {skills.map((skill, index) => (
          <motion.div
            key={skill.name}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
            className="bg-primary/10 p-4 rounded-lg"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="text-lg font-semibold mb-2">{skill.name}</h3>
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
              <motion.div
                className="bg-primary h-2.5 rounded-full"
                initial={{ width: 0 }}
                animate={{ width: `${skill.level}%` }}
                transition={{ duration: 1, delay: 0.5 }}
              />
            </div>
          </motion.div>
        ))}
      </div>
    </motion.section>
  )
}