import React from 'react'
import { motion } from 'framer-motion'
import { Sun, Moon, User, Code, Briefcase } from 'lucide-react'
import NavItem from './NavItem'

interface HeaderProps {
  activeSection: string
  setActiveSection: (section: string) => void
  theme: string
  toggleTheme: () => void
}

export default function Header({ activeSection, setActiveSection, theme, toggleTheme }: HeaderProps) {
  return (
    <header className="container mx-auto px-4 py-8 relative z-10">
      <nav className="flex justify-between items-center">
        <motion.h1 
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
          className="text-3xl font-bold"
        >
          Eric Gonzalez Duro
        </motion.h1>
        <div className="flex space-x-4">
          <NavItem icon={<User size={20} />} label="About" active={activeSection === 'about'} onClick={() => setActiveSection('about')} />
          <NavItem icon={<Code size={20} />} label="Skills" active={activeSection === 'skills'} onClick={() => setActiveSection('skills')} />
          <NavItem icon={<Briefcase size={20} />} label="Projects" active={activeSection === 'projects'} onClick={() => setActiveSection('projects')} />
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={toggleTheme}
            className="p-2 rounded-lg bg-primary text-primary-foreground"
          >
            {theme === 'dark' ? <Sun size={20} /> : <Moon size={20} />}
          </motion.button>
        </div>
      </nav>
    </header>
  )
}