import React from 'react'
import { motion } from 'framer-motion'

interface NavItemProps {
  icon: React.ReactNode
  label: string
  active: boolean
  onClick: () => void
}

export default function NavItem({ icon, label, active, onClick }: NavItemProps) {
  return (
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={onClick}
      className={`flex items-center p-2 rounded-lg ${active ? 'bg-primary text-primary-foreground' : 'text-muted-foreground hover:bg-primary/10'}`}
    >
      {icon}
      <span className="ml-2">{label}</span>
    </motion.button>
  )
}