import React from 'react'
import { motion } from 'framer-motion'
import { Mail, Linkedin, MapPin, Phone } from 'lucide-react'

export default function ContactSection() {
  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, delay: 0.2 }}
      className="text-center"
    >
      <h2 className="text-4xl font-bold mb-8">Contact</h2>
      <div className="flex flex-col items-center space-y-4">
        <motion.a 
          href="mailto:ericgonzalezduro@gmail.com" 
          className="flex items-center hover:text-primary transition-colors"
          whileHover={{ scale: 1.05 }}
        >
          <Mail className="w-5 h-5 mr-2" />
          ericgonzalezduro@gmail.com
        </motion.a>
        <motion.a 
          href="https://www.linkedin.com/in/eric-gonzalez-duro-22b67b227" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="flex items-center hover:text-primary transition-colors"
          whileHover={{ scale: 1.05 }}
        >
          <Linkedin className="w-5 h-5 mr-2" />
          LinkedIn Profile
        </motion.a>
        <motion.div 
          className="flex items-center"
          whileHover={{ scale: 1.05 }}
        >
          <MapPin className="w-5 h-5 mr-2" />
          Av Tarragona 101 2n 1a, AD500 Andorra la Vella (Principat d'Andorra)
        </motion.div>
        <motion.div 
          className="flex items-center"
          whileHover={{ scale: 1.05 }}
        >
          <Phone className="w-5 h-5 mr-2" />
          +34 656 478 361
        </motion.div>
      </div>
    </motion.section>
  )
}