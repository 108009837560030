import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Github, ExternalLink, ChevronUp, ChevronDown } from 'lucide-react'

interface ProjectCardProps {
  project: {
    name: string
    description: string
    tech: string
    github: string
    website: string
    image: string
  }
  index: number
  isExpanded: boolean
  toggleExpansion: () => void
}

export default function ProjectCard({ project, index, isExpanded, toggleExpansion }: ProjectCardProps) {
  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      className="bg-primary/10 rounded-lg shadow-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300"
      whileHover={{ scale: 1.02 }}
    >
      <img src={project.image} alt={project.name} className="w-full h-48 object-cover" />
      <div className="p-6">
        <h3 className="text-xl font-semibold mb-2">{project.name}</h3>
        <p className="text-muted-foreground mb-4">{project.description}</p>
        <div className="flex justify-between items-center">
          <div className="flex space-x-4">
            <a 
              href={project.github} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="flex items-center text-primary hover:text-primary/80"
            >
              <Github className="w-5 h-5 mr-1" />
              GitHub
            </a>
            <a 
              href={project.website} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="flex items-center text-primary hover:text-primary/80"
            >
              <ExternalLink className="w-5 h-5 mr-1" />
              Website
            </a>
          </div>
          <motion.button
            onClick={toggleExpansion}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            {isExpanded ? <ChevronUp /> : <ChevronDown />}
          </motion.button>
        </div>
        <AnimatePresence>
          {isExpanded && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              className="mt-4"
            >
              <h4 className="font-semibold mb-2">Technologies:</h4>
              <p className="text-sm text-muted-foreground">{project.tech}</p>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  )
}