import React from 'react'
import { motion } from 'framer-motion'
import { GraduationCap, Lightbulb, Rocket, Award, Code, Book } from 'lucide-react'

export default function AboutSection() {
  return (
    <motion.section
      key="about"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
      className="mb-20 px-4 py-8 bg-gradient-to-br from-background to-background/80 rounded-3xl shadow-lg"
    >
      <h2 className="text-4xl font-bold mb-12 text-center bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary">About Me</h2>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <motion.div
          className="flex flex-col items-center space-y-6"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="relative">
            <motion.img
              src="https://media.licdn.com/dms/image/v2/D4E03AQEHEsDGHR1JLA/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1677800328490?e=1733356800&v=beta&t=PH5kDXWgAxaj-TqxDgp3RKv5NeO14_etZKF78ueC4Io"
              alt="Eric Gonzalez Duro"
              className="rounded-full w-64 h-64 object-cover shadow-lg border-4 border-primary/20"
              whileHover={{ scale: 1.05 }}
            />
            <motion.div
              className="absolute -bottom-4 -right-4 bg-primary text-primary-foreground rounded-full p-2"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <Award size={24} />
            </motion.div>
          </div>
          <motion.div 
            className="bg-card text-card-foreground rounded-xl p-6 shadow-md w-full"
            whileHover={{ scale: 1.02 }}
          >
            <h3 className="text-xl font-semibold mb-4 text-primary">Eric Gonzalez Duro</h3>
            <p className="text-muted-foreground">
              Passionate computer engineering student with a love for innovation and problem-solving. Currently in my fourth year at the Polytechnical University of Catalonia.
            </p>
          </motion.div>
        </motion.div>

        <motion.div 
          className="space-y-6"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <motion.div 
            className="bg-card text-card-foreground rounded-xl p-6 shadow-md"
            whileHover={{ scale: 1.02 }}
          >
            <h3 className="text-xl font-semibold mb-4 text-primary flex items-center">
              <Code className="w-6 h-6 mr-2 text-secondary" />
              Expertise
            </h3>
            <ul className="space-y-2">
              <li className="flex items-center">
                <Lightbulb className="w-5 h-5 mr-2 text-secondary" />
                <span>Blockchain Technology</span>
              </li>
              <li className="flex items-center">
                <Lightbulb className="w-5 h-5 mr-2 text-secondary" />
                <span>Algorithmic Trading</span>
              </li>
              <li className="flex items-center">
                <Lightbulb className="w-5 h-5 mr-2 text-secondary" />
                <span>AI-driven Solutions</span>
              </li>
            </ul>
          </motion.div>

          <motion.div 
            className="bg-card text-card-foreground rounded-xl p-6 shadow-md"
            whileHover={{ scale: 1.02 }}
          >
            <h3 className="text-xl font-semibold mb-4 text-primary flex items-center">
              <Award className="w-6 h-6 mr-2 text-secondary" />
              Achievements
            </h3>
            <ul className="space-y-2">
              <li className="flex items-center">
                <GraduationCap className="w-5 h-5 mr-2 text-secondary" />
                <span>4th Year at Polytechnical University of Catalonia</span>
              </li>
              <li className="flex items-center">
                <Award className="w-5 h-5 mr-2 text-secondary" />
                <span>3rd Big Prize Winner at HackMIT 2023</span>
              </li>
              <li className="flex items-center">
                <Rocket className="w-5 h-5 mr-2 text-secondary" />
                <span>Co-founder of Two Tech Startups</span>
              </li>
            </ul>
          </motion.div>
        </motion.div>
      </div>

      <motion.div
        className="mt-8 bg-card text-card-foreground rounded-xl p-6 shadow-md"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.4 }}
      >
        <h3 className="text-xl font-semibold mb-4 text-primary flex items-center">
          <Book className="w-6 h-6 mr-2 text-secondary" />
          My Journey
        </h3>
        <p className="text-muted-foreground leading-relaxed">
          My journey in tech is driven by curiosity and a relentless pursuit of knowledge. I'm always excited to tackle new challenges and create solutions that make a difference in the world of technology. From winning competitions to co-founding startups, every experience has shaped my path and fueled my passion for innovation. I believe in the power of technology to transform industries and improve lives, and I'm committed to being at the forefront of this exciting field.
        </p>
      </motion.div>
    </motion.section>
  )
}